import React, { useState, useEffect } from 'react';

const QuantitySelector = ({ initialQty = 1, onIncrement, onDecrement }) => {
  const [qty, setQty] = useState(initialQty);

  // Update the qty state whenever initialQty changes
  useEffect(() => {
    setQty(initialQty);
  }, [initialQty]);

  const incrementQty = () => {
    setQty(qty + 1);
    onIncrement(); // Call the parent function to update the cart
  };

  const decrementQty = () => {
    if (qty > 1) {
      setQty(qty - 1);
      onDecrement(); // Call the parent function to update the cart
    }
  };

  return (
    <div className="row g-2 enumerator flex-sm-row flex-column align-items-center">
      <div className="col-auto">
        <button type="button" onClick={decrementQty}>
          <i className="fa fa-minus"></i>
        </button>
      </div>
      <div className="col-auto">
        <input
          type="text"
          id="quantity"
          value={qty}
          readOnly
          min="1"
        />
      </div>
      <div className="col-auto">
        <button type="button" onClick={incrementQty}>
          <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
};

export default QuantitySelector;
