import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CategoryCard from '../components/CategoryCard';
import ProductCard from '../components/ProductCard';

const Page = () => {
  const { slug } = useParams();
  const [page, setPage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true); // Tracks initial load
  const [error, setError] = useState(null);
  const { title, phone } = useOutletContext();

  // Fetch page content based on slug
  useEffect(() => {
    const fetchPageData = async (pageSlug) => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/page/${pageSlug}`);
        if (!response.ok) throw new Error('Failed to fetch page');
        const data = await response.json();
        setPage(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
      } finally {
        setFirstLoad(false); // Mark first load as complete
      }

    };

    fetchPageData(slug || '');
  }, [slug]);

  // Separate fetch functions for categories and products
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/categories/${process.env.REACT_APP_USERNAME}`);
      if (!response.ok) throw new Error('Failed to fetch categories');
      setCategories(await response.json());
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/products/${process.env.REACT_APP_USERNAME}`);
      if (!response.ok) throw new Error('Failed to fetch products');
      setProducts(await response.json());
    } catch (err) {
      setError(err.message);
    }
  };

  const renderCategories = () => (
      <div className="categories mb-lg-6 mb-4">
        <div className="container">
          <div className="row g-2">
            {categories.map((category) => (
                <div key={category.id} className="col-lg-3 col-md-4 col-sm-6">
                  <CategoryCard category={category} endpoint={process.env.REACT_APP_ENDPOINT} />
                </div>
            ))}
          </div>
        </div>
      </div>
  );

  const renderProducts = () => (
      <div className="products mb-lg-6 mb-4">
        <div className="container">
          <div className="row g-md-4 g-3">
            {products.map((product) => (
                <div key={product.id} className="col-lg-3 col-md-4 col-sm-6">
                  <ProductCard product={product} endpoint={process.env.REACT_APP_ENDPOINT} />
                </div>
            ))}
          </div>
        </div>
      </div>
  );

  const renderForm = () => (
      <section className="py-lg-6 py-4">
        <div className="container container-sm">
          <h2 className="mb-4 text-center">Envianos un mensaje</h2>
          <form className="ajax-form" onSubmit={handleFormSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Nombre</label>
              <input type="text" id="name" name="name" className="form-control" required />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Teléfono</label>
              <input type="text" id="phone" name="phone" className="form-control" required />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" id="email" name="email" className="form-control" required />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Mensaje</label>
              <textarea id="message" name="message" className="form-control" rows="4" required></textarea>
            </div>
            <div className="mb-3">
              <button type="submit" id="submitButton" className="btn btn-primary">Enviar mensaje</button>
            </div>
          </form>
        </div>
      </section>
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const alertBox = document.getElementById('alertBox');
    const inputs = Array.from(form.elements).filter((el) => el.tagName === 'INPUT' || el.tagName === 'TEXTAREA');

    // Hide alert on input focus
    inputs.forEach((input) => {
      input.addEventListener('focus', () => {
        alertBox.classList.add('d-none');
      });
    });

    const data = Object.fromEntries(formData.entries());

    const { name, email, message } = data;

    // Create WhatsApp link
    const whatsappText = `Hola, mi nombre es ${name}. Mi email es ${email}. ${message}`;
    const encodedText = encodeURIComponent(whatsappText);
    const whatsappUrl = `https://wa.me/${phone}?text=${encodedText}`;

    // Open WhatsApp link in a new tab
    window.open(whatsappUrl, '_blank');
  };

  const renderContent = () => {
    if (!page?.content) return null;

    const parts = [];
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = page.content;

    // Append any remaining HTML content to parts
    while (tempDiv.firstChild) {
      parts.push(
          <div key={`content-${parts.length}`} dangerouslySetInnerHTML={{__html: tempDiv.firstChild.outerHTML }} />
      );
      tempDiv.removeChild(tempDiv.firstChild);
    }

    // Replace placeholders with rendered components
    return parts.map((part, index) => {
      const content = part.props.dangerouslySetInnerHTML?.__html;

      if (content?.includes('category-block')) {
        return renderCategories();
      }
      if (content?.includes('product-block')) {
        return renderProducts();
      }
      if (content?.includes('form-block')) {
        return renderForm();
      }
      return part;
    });
  };


  useEffect(() => {
    const checkAndFetchData = async () => {
      const needsCategories = page?.content?.includes('category-block');
      const needsProducts = page?.content?.includes('product-block');

      if (needsCategories) await fetchCategories();
      if (needsProducts) await fetchProducts();

      setLoading(false);
    };

    checkAndFetchData();
  }, [page]);

  if (loading) {
    return (
        <section className="py-lg-6 py-4">
          <div className="container text-center">
            <i className="fa fa-spinner fa-spin spinner"></i>
          </div>
        </section>
    );
  }
  if (firstLoad) return <div className="loader"><img src="/favicon.png" alt="Loading..." /></div>;
  if (error) return <div>Error: {error}</div>;

  return (
      <>
        <Helmet>
          <title>{title ?? ''} - {page?.title}</title>
          <meta name="description" content="" />
          <meta property="og:title" content="" />
          <meta property="og:description" content="" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="" />
          <meta property="og:image" content="" />
        </Helmet>

        {renderContent()}
      </>
  );
};

export default Page;
