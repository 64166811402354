import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Toast, ToastContainer, Button } from 'react-bootstrap';
import { useCart } from 'react-use-cart';
import QuantitySelector from '../components/QuantitySelector';
import ProductCard from '../components/ProductCard';

function ProductPage() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [qty, setQty] = useState(1);
  const [showToast, setShowToast] = useState(false);
  const endpoint = process.env.REACT_APP_ENDPOINT;
  const username = process.env.REACT_APP_USERNAME;
  const { title } = useOutletContext(); // Access the title from Outlet context

  const { addItem } = useCart();

  useEffect(() => {
    fetch(`${endpoint}/api/product/${username}/${id}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setProduct(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching product:', error);
          setLoading(false);
        });
  }, [endpoint, id, username]);

  const handleAddToCart = () => {
    addItem({ id: product.id, ...product }, qty);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const handleIncrement = () => {
    setQty((prevQty) => prevQty + 1);
  };

  const handleDecrement = () => {
    setQty((prevQty) => Math.max(1, prevQty - 1));
  };

  if (loading) {
    return (
        <section className="py-lg-6 py-4">
          <div className="container text-center">
            <i className="fa fa-spinner fa-spin spinner"></i>
          </div>
        </section>
    );
  }

  if (!product) {
    return <h1>Product not found</h1>;
  }

  return (
      <div>
        <Helmet>
          <title>{title} - {product.name}</title>
          <meta name="description" content=""/>
          <meta property="og:title" content=""/>
          <meta property="og:description" content=""/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content=""/>
          <meta property="og:image" content=""/>
        </Helmet>

        <section className="product py-lg-6 py-4">
          <div className="container">
            <nav>
              <ol className="breadcrumb mb-3">
                <li className="breadcrumb-item">
                  <Link to={'/'}>Productos</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/categorias/${product.category.slug}`}>{product.category.name}</Link>
                </li>
                <li className="breadcrumb-item active">{product.name}</li>
              </ol>
            </nav>
            <div className="row g-lg-5 g-md-4 g-3">
              <div className="col-md-6">
                {product.images.length > 0 && (
                    <div className="ratio ratio-1x1">
                      <img
                          src={`${endpoint}/storage/${product.images[0].src}`}
                          alt={product.name}
                          className="img-absolute"
                      />
                    </div>
                )}
              </div>
              <div className="col-md-6">
                <h3>{product.name}</h3>
                <p>Cód. {product.sku}</p>

                {product.price && (
                    product.sale_price ? (
                        <>
                          <h4 className="text-muted text-decoration-line-through">
                            Gs. {parseFloat(product.price).toLocaleString('es-PY')}
                          </h4>
                          <h2 className="text-danger">
                            Gs. {parseFloat(product.sale_price).toLocaleString('es-PY')}
                          </h2>
                        </>
                    ) : (
                        <h2>
                          Gs. {parseFloat(product.price).toLocaleString('es-PY')}
                        </h2>
                    )
                )}

                {product.text && <div className="content-html mb-3"><p>{product.text}</p></div>}
                <div>
                  <label htmlFor="quantity" className="mb-3">Cantidad</label>
                  <QuantitySelector
                      initialQty={qty}
                      onIncrement={handleIncrement}
                      onDecrement={handleDecrement}
                  />
                  <div className="mt-4">
                    <Button onClick={handleAddToCart} className="btn btn-primary">
                      Agregar a lista de consulta
                    </Button>
                  </div>
                </div>
                <p className="mt-3">Categoría: <Link to={`/categorias/${product.category.slug}`}>{product.category.name}</Link></p>
              </div>
            </div>
          </div>
        </section>

        {product.description && (
            <section className="product py-lg-6 py-4">
              <div className="container">
                <h6 className="mb-4 text-primary">Características principales</h6>
                <div
                    className="content-html"
                    dangerouslySetInnerHTML={{ __html: product.description }}
                />
              </div>
            </section>
        )}

        {/* Conditionally render related products section */}
        {product.related_products && product.related_products.length > 0 && (
            <section className="products py-lg-6 py-4">
              <div className="container">
                <h2 className="mb-lg-6 mb-4">Productos relacionados</h2>
                <div className="row g-md-4 g-3">
                  {product.related_products.map((relatedProduct) => (
                      <div className="col-lg-3 col-md-4 col-sm-6" key={relatedProduct.id}>
                        <ProductCard
                            product={relatedProduct}
                            endpoint={endpoint}
                        />
                      </div>
                  ))}
                </div>
              </div>
            </section>
        )}

        <ToastContainer position="top-end" className="p-3 position-fixed top-0 end-0">
          <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={3000}
              autohide
          >
            <Toast.Header>
              <strong className="me-auto">Producto Agregado</strong>
            </Toast.Header>
            <Toast.Body>{product.name} ha sido agregado a tu lista de consulta!</Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
  );
}

export default ProductPage;
