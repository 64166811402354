import React from 'react';
import { Link } from 'react-router-dom';

const CategoryCard = ({ category, endpoint }) => {
  return (
      <Link to={`/categorias/${category.slug}`} className="category-link">
        <div className="ratio">
          <img
              src={`${endpoint}/storage/${category.image}`}
              alt={category.name}
              className="img-cover"
          />
          <div className="ratio-overlay p-md-4 p-3">
            <h4 className="m-0 p-md-4 p-3">{category.name}</h4>
          </div>
        </div>
      </Link>
  );
};

export default CategoryCard;
