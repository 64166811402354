import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useCart } from 'react-use-cart';

const Layout = () => {
  const { totalItems } = useCart();
  const [store, setStore] = useState({});
  const [pages, setPages] = useState([]);
  const [title, setTitle] = useState();
  const [phone, setPhone] = useState();

  const endpoint = process.env.REACT_APP_ENDPOINT;
  const username = process.env.REACT_APP_USERNAME;

  useEffect(() => {
    fetch(`${endpoint}/api/user/${username}`)
        .then((response) => response.json())
        .then((data) => {
          setStore(data);
          setPages(data.pages);
          setPhone(data.phone);
          setTitle(data.store_name);
        })
        .catch((error) => console.error('Error fetching store data:', error));
  }, [endpoint, username]);

  return (
      <>
          <header className="header py-lg-6 py-md-4 py-3">
              <div className="container">
                  <div className="row g-lg-5 g-md-4 g-3 justify-content-between align-items-center">

                      {/* Logo Section */}
                      <div className="col-auto">
                          <Link to="/">
                              <img
                                  src={`${process.env.REACT_APP_ENDPOINT}/storage/${store.image}`}
                                  alt="Logo"
                                  className="logo"
                              />
                          </Link>
                      </div>

                      {/* Navigation Section */}
                      <div className="col-auto d-lg-block d-none">
                          <ul className="list-unstyled m-0 d-flex">
                              {pages.map((page, index) => (
                                  <li className="nav-item" key={index}>
                                      {/* Check if the page is a dropdown menu with categories */}
                                      {page.slug === "#" && page.children ? (
                                          <div className="nav-item dropdown">
                                              <Link
                                                  className="nav-link dropdown-toggle"
                                                  to="#"
                                                  id={`dropdown-${index}`}
                                                  role="button"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                              >
                                                  {page.menu_title}
                                              </Link>
                                              {/* Dropdown menu for categories */}
                                              <ul className="dropdown-menu" aria-labelledby={`dropdown-${index}`}>
                                                  {page.children.map((category) => (
                                                      <li className="nav-item" key={category.slug}>
                                                          <Link className="dropdown-item" to={category.slug}>
                                                              {category.menu_title}
                                                          </Link>
                                                      </li>
                                                  ))}
                                              </ul>
                                          </div>
                                      ) : (
                                          // Render standard link if not a dropdown
                                          <Link className="nav-link" to={page.slug}>
                                              {page.menu_title}
                                          </Link>
                                      )}
                                  </li>
                              ))}
                          </ul>
                      </div>

                      {/* Cart Section */}
                      <div className="col-auto">
                          <Link to="/cart" className="btn-cart position-relative">
                              <i className="fa fa-list-check"></i>
                              {totalItems > 0 && (
                                  <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                    {totalItems}
                  </span>
                              )}
                          </Link>
                      </div>

                      {/* Offcanvas Toggle Button */}
                      <div className="col-auto d-lg-none">
                          <button
                              className="btn btn-outline-primary btn-sm"
                              type="button"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasMenu"
                              aria-controls="offcanvasMenu"
                          >
                              <i className="fa fa-bars"></i>
                          </button>
                      </div>
                  </div>
              </div>
          </header>

          {/* Offcanvas Menu */}
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasMenu"
               aria-labelledby="offcanvasMenuLabel">
              <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasMenuLabel">{title}</h5>
                  <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                          aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                  <ul className="list-unstyled">
                      {pages.map((page, index) => (
                          <li className={`nav-item ${index ? 'mt-3' : ''}`} key={index}>
                              <Link className="nav-link" to={page.slug}>
                                  {page.menu_title}
                              </Link>
                          </li>
                      ))}
                  </ul>
              </div>
          </div>

          <main>
              <Outlet context={{title, phone}}/>
          </main>

          <footer className="footer py-lg-6 py-4">
              <div className="container">
                  <div className="row g-lg-5 g-md-4 g-3 justify-content-between">
                      <div className="col-auto mx-sm-auto">
                          <ul className="list-unstyled m-0">
                              {pages.map((page, index) => (
                                  !page.children && (
                                      <li className="nav-item" key={index}>
                                          <Link className="nav-link" to={page.slug}>
                                              {page.menu_title}
                                          </Link>
                                      </li>
                                  )
                              ))}
                          </ul>
                      </div>
                      <div className="col-auto">
                          <div className="row g-3">
                              <div className="col-auto">
                                  <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                      <i className="fab fa-facebook-f"></i>
                                  </a>
                              </div>
                              <div className="col-auto">
                                  <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                      <i className="fab fa-twitter"></i>
                                  </a>
                              </div>
                              <div className="col-auto">
                                  <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                      <i className="fab fa-instagram"></i>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
      </>
  );
};

export default Layout;
