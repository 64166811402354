import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import { CartProvider } from 'react-use-cart'; // Import CartProvider

// Import your page components
import CategoryPage from './pages/CategoryPage';
import ProductPage from './pages/ProductPage';
import Cart from './pages/Cart';
import Page from './pages/Page'; // Dynamic Page Component
import Layout from './components/Layout'; // Layout Component

function App() {
  return (
      <HelmetProvider> {/* Wrap your app with HelmetProvider for SEO */}
        <CartProvider> {/* Wrap with CartProvider for cart functionality */}
          <Router basename="/">
            <Routes>
              <Route path="/" element={<Layout />}> {/* Use Layout here */}
                {/* Render the dynamic Page component for the root page ("/") */}
                <Route path="/" element={<Page />} />
                <Route path="categorias/:slug" element={<CategoryPage />} />
                <Route path="productos/:id/:slug" element={<ProductPage />} />
                <Route path="cart" element={<Cart />} />
                <Route path="/:slug" element={<Page />} /> {/* Other dynamic pages */}
              </Route>
            </Routes>
          </Router>
        </CartProvider>
      </HelmetProvider>
  );
}

export default App;
