import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ProductCard from "../components/ProductCard";

const CategoryPage = () => {
  const { slug } = useParams();
  const [category, setCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const { title } = useOutletContext();

  // State to track expanded categories in the sidebar
  const [expandedCategories, setExpandedCategories] = useState({});

  const endpoint = process.env.REACT_APP_ENDPOINT;
  const username = process.env.REACT_APP_USERNAME;

  useEffect(() => {
    setLoading(true);
    fetch(`${endpoint}/api/category/${username}/${slug}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch category data');
          }
          return response.json();
        })
        .then(data => {
          setCategory(data.category);
          setProducts(data.products);
          setLoading(false);
        })
        .catch(err => {
          setError(err.message);
          setLoading(false);
        });

    fetch(`${endpoint}/api/categories/${username}`)
        .then((response) => response.json())
        .then((data) => setCategories(data))
        .catch((error) => console.error('Error fetching categories:', error));

  }, [slug, endpoint, username]);

  // If still loading, show a loading message
  if (loading) {
    return (
        <section className="py-lg-6 py-4">
          <div className="container text-center">
            <i className="fa fa-spinner fa-spin spinner"></i>
          </div>
        </section>
    );
  }

  // If there was an error, show the error message
  if (error) {
    return <p>Error: {error}</p>;
  }

  // Toggle the expanded state of a category
  const toggleCategory = (categorySlug) => {
    setExpandedCategories((prevExpanded) => ({
      ...prevExpanded,
      [categorySlug]: !prevExpanded[categorySlug],
    }));
  };

  // Recursive function to render categories with nested children
  const renderCategory = (category) => (
      <li key={category.slug} className="mb-3">
        <div className="d-flex align-items-center justify-content-between mt-3">
          <Link to={`/categorias/${category.slug}`} className={category.slug === slug ? 'active' : ''}>
            {category.name}
          </Link>
          {category.children && category.children.length > 0 && (
              <button
                  onClick={() => toggleCategory(category.slug)}
                  className="btn btn-sm btn-link ms-2"
                  aria-expanded={!!expandedCategories[category.slug]}
                  aria-controls={`children-${category.slug}`}
              >
                {expandedCategories[category.slug] ? <i className="fa fa-minus"></i> : <i className="fa fa-plus"></i>}
              </button>
          )}
        </div>
        {category.children && category.children.length > 0 && expandedCategories[category.slug] && (
            <ul id={`children-${category.slug}`} className="list-unstyled ms-3">
              {category.children.map(child => renderCategory(child))}
            </ul>
        )}
      </li>
  );

  return (
      <div>
        {/* Add meta tags with Helmet */}
        <Helmet>
          <title>{title} - {category?.name}</title>
          <meta name="description" content=""/>
          <meta property="og:title" content=""/>
          <meta property="og:description" content=""/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content=""/>
          <meta property="og:image" content=""/>
        </Helmet>

        {/* Category Section */}
        <section className="category py-lg-6 py-4">
          <div className="container">
            <nav>
              <ol className="breadcrumb mb-3">
                <li className="breadcrumb-item">
                  <Link to={'/'}>Productos</Link>
                </li>
                <li className="breadcrumb-item active">{category?.name}</li>
              </ol>
            </nav>
            <h2 className="mb-lg-6 mb-4">{category ? category.name : 'Categoría'}</h2>
            <div className="row g-lg-5 g-4">
              <div className="col-lg-3 col-md-4">
                <aside className="sidebar">
                  <h6 className="mb-4">Categorías</h6>
                  <ul className="list-unstyled m-0">
                    {categories.length > 0 ? (
                        categories.map((category) => renderCategory(category))
                    ) : (
                        <p>No se han encontrado categorías.</p>
                    )}
                  </ul>
                </aside>
              </div>
              <div className="col-lg-9 col-md-8">
                {/* Products Section */}
                <div className="row g-3">
                  {products.length > 0 ? (
                      products.map((product) => (
                          <div className="col-md-4 col-sm-6" key={product.id}>
                            <ProductCard product={product} endpoint={endpoint}/>
                          </div>
                      ))
                  ) : (
                      <p>No se han encontrado productos.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
};

export default CategoryPage;
