import React from 'react';
import { Link } from 'react-router-dom';

// Utility function to create a slug from a title
const createSlug = (name) => {
    return name
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen
        .trim(); // Trim leading and trailing hyphens
};

const ProductCard = ({ product, endpoint }) => {
    const slug = createSlug(product.name); // Create slug from product title

    return (
        <div className="d-flex h-100 flex-column">
            <Link to={`/productos/${product.id}/${slug}`}>
                <div className="ratio ratio-1x1">
                    {Array.isArray(product.images) && product.images.length > 0 && (
                        <img
                            src={`${endpoint}/storage/${product.images[0].src}`}
                            alt={product.name}
                            className="img-absolute"
                        />
                    )}
                </div>
            </Link>
            <p className="mt-2 mb-0">{product.name}</p>

            {product.price && (
                product.sale_price ? (
                    <>
                        <h6 className="mt-2 text-muted text-decoration-line-through">
                            <small>Gs. {parseFloat(product.price).toLocaleString('es-PY')}</small>
                        </h6>
                        <h6 className="mt-1 text-danger">
                            Gs. {parseFloat(product.sale_price).toLocaleString('es-PY')}
                        </h6>
                    </>
                ) : (
                    <h6 className="mt-2">
                        Gs. {parseFloat(product.price).toLocaleString('es-PY')}
                    </h6>
                )
            )}
            <div className="my-auto"></div>
            <div className="mt-md-4 mt-3 text-center">
                <Link to={`/productos/${product.id}/${slug}`} className="btn btn-primary btn-sm">
                    Ver más
                </Link>
            </div>
        </div>
    );
};

export default ProductCard;
