import React, { useState } from 'react';
import { useCart } from 'react-use-cart';
import { Helmet } from 'react-helmet-async';
import QuantitySelector from '../components/QuantitySelector';
import {Link, useOutletContext} from "react-router-dom";

function Cart() {
  const {
    isEmpty,
    items,
    updateItemQuantity,
    removeItem,
  } = useCart();
  const { phone } = useOutletContext(); // Access the title from Outlet context

  const cartTotal = items.reduce((total, item) => {
    const effectivePrice = item.sale_price ? item.sale_price : item.price;
    return total + effectivePrice * item.quantity;
  }, 0);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');

  const createWhatsAppMessage = () => {
    const products = items.map(item => {
      const price = item.sale_price || item.price;
      return `${item.name} - Cantidad: ${item.quantity} - Precio: Gs. ${parseFloat(price).toLocaleString('es-PY')}`;
    }).join('\n');

    const total = `Total: Gs. ${parseFloat(cartTotal).toLocaleString('es-PY')}`;
    const message = `Nombre: ${name}\nEmail: ${email}\nDirección: ${address}\n\nProductos:\n${products}\n\n${total}`;
    return message;
  };

  const handleSend = () => {
    const message = createWhatsAppMessage();
    const url = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
      <div>
        <Helmet>
          <title>Tu lista de consulta</title>
          <meta name="description" content=""/>
          <meta property="og:title" content=""/>
          <meta property="og:description" content=""/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content=""/>
          <meta property="og:image" content=""/>
        </Helmet>

        <section className="py-lg-6 py-4">
          <div className="container">
            <nav>
              <ol className="breadcrumb mb-3">
                <li className="breadcrumb-item">
                  <Link to={'/'}>Productos</Link>
                </li>
                <li className="breadcrumb-item active">Lista de consulta</li>
              </ol>
            </nav>

            {/* Main Content */}
            <div className="row g-lg-5 g-md-4 g-3">
              <div className="col-lg-8">
                {/* Show empty message if the cart is empty */}
                {isEmpty ? (
                    <div className="content-html">
                      <p>Tu lista de consulta está vacía.</p>
                      <Link to="/" className="btn btn-primary">Ir al inicio</Link>
                    </div>
                ) : (
                    <>
                      <h4 className="mb-4">Tu lista de consulta</h4>
                      <table className="table table-borderless">
                        <thead>
                        <tr>
                          <th>Producto</th>
                          <th>Cantidad</th>
                          <th>Precio</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map(item => (
                            <tr key={item.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  {item.images && item.images[0] && (
                                      <img
                                          src={`${process.env.REACT_APP_ENDPOINT}/storage/${item.images[0].thumbnail}`}
                                          alt={item.name}
                                          style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                          className="me-3"
                                      />
                                  )}
                                  {item.name}
                                </div>
                              </td>
                              <td>
                                <QuantitySelector
                                    initialQty={item.quantity}
                                    onIncrement={() => updateItemQuantity(item.id, item.quantity + 1)}
                                    onDecrement={() => updateItemQuantity(item.id, item.quantity - 1)}
                                />
                              </td>
                              <td>
                                {item.sale_price ? (
                                    <>
                                <span className="text-muted text-decoration-line-through">
                                  <small>Gs. {parseFloat(item.price).toLocaleString('es-PY')}</small>
                                </span>
                                      <br />
                                      <span className="text-danger">
                                  Gs. {parseFloat(item.sale_price).toLocaleString('es-PY')}
                                </span>
                                    </>
                                ) : (
                                    `Gs. ${parseFloat(item.price).toLocaleString('es-PY')}`
                                )}
                              </td>
                              <td>
                                Gs. {parseFloat(item.quantity * (item.sale_price || item.price)).toLocaleString('es-PY')}
                              </td>
                              <td>
                                <button className="btn-icon" onClick={() => removeItem(item.id)}>
                                  <i className="fa fa-times"></i>
                                </button>
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </>
                )}

                {!isEmpty && (
                    <div className="total mt-4">
                      <h5>Total: Gs. {parseFloat(cartTotal).toLocaleString('es-PY')}</h5>
                    </div>
                )}
              </div>

              {!isEmpty && (
                  <div className="col-lg-4">
                    <h4 className="mb-4">Tus datos</h4>
                    <form>
                      <div className="mb-3">
                        <label htmlFor="formName" className="form-label mb-3">Nombre</label>
                        <input
                            type="text"
                            className="form-control"
                            id="formName"
                            placeholder="Ingresa tu nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="formEmail" className="form-label mb-3">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="formEmail"
                            placeholder="Ingresa tu email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="formAddress" className="form-label mb-3">Dirección</label>
                        <input
                            type="text"
                            className="form-control"
                            id="formAddress"
                            placeholder="Ingresa tu dirección"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>

                      <button type="button" className="btn btn-primary mt-3" onClick={handleSend}>
                        Hacer consulta
                      </button>
                    </form>
                  </div>
              )}
            </div>
          </div>
        </section>
      </div>
  );
}

export default Cart;
